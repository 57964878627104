const InputField = (props) => {
  const handleChange = (e) => {
    props.OnChange(e.target.value);
  };
  if (props.typeN) {
    return (
      <div className="py-2 w-5/6 m-auto left-0 right-0 text-white">
        <input
          type="tel"
          value={props.value}
          maxLength="10"
          className="border-0 pb-4 bg-transparent border-b autofill:bg-transparent autofill:text-violet  w-full placeholder-white focus:outline-none"
          placeholder="mobile number*"
          name={props.name}
          onChange={handleChange}
          pattern="\d*"
          title="Only US Phone Numbers are allowed"
        ></input>
      </div>
    );
  }
  return (
    <div className="py-2 w-5/6 m-auto left-0 right-0 text-white">
      <input
        type={props.type}
        value={props.value}
        maxLength={props.max}
        className="border-0 pb-4 bg-transparent border-b autofill:bg-transparent autofill:text-violet  w-full placeholder-white focus:outline-none"
        placeholder={props.label}
        name={props.name}
        onChange={handleChange}
      ></input>
    </div>
  );
};
export default InputField;

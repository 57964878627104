import React, { useState } from "react";
import { motion } from "framer-motion";
import { HOME_BG } from "../../Images";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  FacebookMessengerIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

const SocialIcons = (props) => {
  return (
    <motion.div
      className={props.hidden ? "flex flex-row gap-5" : "hidden"}
      initial={{ opacity: 0 }}
      animate={props.hidden ? { opacity: 1 } : {}}
      transition={{
        delay: 0.5,
        ease: "easeIn",
        type: "keyframes",
        duration: 1.5,
      }}
    >
      <FacebookShareButton url={props.url}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={props.url}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <FacebookShareButton url={props.url}>
        <FacebookMessengerIcon size={32} round />
      </FacebookShareButton>
      <LinkedinShareButton url={props.url}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <WhatsappShareButton url={props.url}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <EmailShareButton url={props.url}>
        <img src={HOME_BG.email} />
      </EmailShareButton>
    </motion.div>
  );
};

const SocialShare = (props) => {
  const URL = window.location.href;
  const [share, setShare] = useState(false);

  const HandleShare = () => {
    setShare(!share);
    console.log(props.form);
  };

  return (
    <motion.div
      id="share"
      className=" absolute md:bottom-20 lg:bottom-0 m-auto left-0 right-0 bottom-0 py-10 w-fit h-fit bg-transparent z-20 justify-center  "
      initial={{ filter: "blur(0px)" }}
      animate={props.form ? { filter: "blur(20px)" } : {}}
      transition={{ ease: "easeIn", duration: 1 }}
    >
      <motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={share ? { opacity: 1 } : ""}
          transition={{
            ease: "easeIn",
            type: "keyframes",
          }}
        >
          <SocialIcons url={URL} hidden />
        </motion.div>

        <motion.button
          onClick={HandleShare}
          className="flex flex-row gap-1 m-auto left-0 right-0 pt-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 6,
            ease: "easeIn",
            type: "keyframes",
            duration: 2,
          }}
        >
          <motion.p className="text-white">share</motion.p>
          <motion.img src={HOME_BG.ShareLogo} className=" w-[20px] h-[20px]" />
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default SocialShare;

import React from "react";
import { motion } from "framer-motion";
const Meet = () => {
  return (
    <motion.div
      className=" absolute  m-auto left-0 right-0 text-white font-bold  text-center text-4xl z-30 "
      initial={{ opacity: 1, paddingTop: "9rem" }}
      animate={{ opacity: 0, paddingTop: 0 }}
      transition={{
        delay: 3.5,
        ease: "anticipate",
        type: "tween",
        duration: 3,
      }}
    >
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 2, type: "tween", ease: "easeOut" }}
      >
        meet
      </motion.p>
    </motion.div>
  );
};

export default Meet;

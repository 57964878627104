import React from "react";
import { motion } from "framer-motion";
import { HOME_BG } from "../../Images";
const EddieImg = (props) => {
  return (
    <motion.div
      id="EddieImage"
      className="absolute m-auto bottom-0 left-0 right-0 md:w-[100%]  sm:h-[60%] h-fit w-fit z-10 bg-transparent"
      initial={{ filter: "blur(0px)" }}
      animate={props.form ? { filter: "blur(20px)" } : {}}
      transition={{ ease: "easeIn", duration: 1 }}
    >
      <motion.img
        src={HOME_BG.eddie}
        className="relative -bottom-12 m-auto  md:-left-6 -left-2  right-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 4.2,
          ease: "easeIn",
          type: "keyframes",
          duration: 2,
        }}
      />
    </motion.div>
  );
};

export default EddieImg;

import React from "react";
import { motion } from "framer-motion";
import { HOME_BG } from "../Images";
import SocialShare from "./Components/share";

const Eddie = (props) => {
  const setForm = () => {
    props.HandleFormProp();
  };

  return (
    <motion.div
      id="Eddie"
      className="absolute  m-auto left-0 right-0 z-10 text-white content-center  flex-col justify-center  "
    >
      <motion.div>
        <motion.img
          src={HOME_BG.EddieLogo}
          className=" m-auto left-0 right-0 scale-75 md:scale-90"
          initial={{ opacity: 0, paddingTop: "20vh" }}
          animate={{ opacity: 1, paddingTop: "10vh" }}
          transition={{
            delay: 3.2,
            ease: "anticipate",
            type: "tween",
            duration: 4,
          }}
        ></motion.img>
      </motion.div>

      <motion.div
        id="Content"
        initial={{ filter: "blur(0px)" }}
        animate={props.formBool ? { filter: "blur(20px)" } : {}}
        transition={{ ease: "easeIn", duration: 1 }}
      >
        <motion.p
          className=" mt-4 text-base md:text-xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 5,
            ease: "anticipate",
            type: "tween",
            duration: 4,
          }}
        >
          omnipresent. simple. caring. connected
        </motion.p>
        <motion.button
          className={
            props.form
              ? "hidden"
              : "bg-transparent md:hover:text-[#CBEBFF] w-fit h-fit my-10  text-white font-semibold px-10  rounded-full"
          }
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 5,
            ease: "anticipate",
            type: "tween",
            duration: 4,
          }}
          onClick={setForm}
          disabled={props.form}
        >
          <img
            src={HOME_BG.button}
            className=" absolute w-auto ml-[-3.3rem] mt-[-0.8rem] scale-90"
          />
          be the first to know
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default Eddie;

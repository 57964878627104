import React, { useState } from "react";
import { motion } from "framer-motion";
import Meet from "../Components/Meet";
import Eddie from "../Components/eddie";

import { HOME_BG } from "../Images";
import Form from "../Components/Form";
import SocialShare from "../Components/Components/share";
import EddieImg from "../Components/Components/EddieImg";

const Home = () => {
  const [form, setform] = useState(false);
  const HandleForm = () => {
    setform(!form);
    console.log(form);
  };
  var viewportWidth = window.innerWidth;
  return (
    <html className=" absolute overflow-clip h-[100svh]  w-screen bg-black font-graphik">
      <body>
        <motion.div className="text-center h-[100svh] md:h-screen relative overflow-hidden">
          <Form form={form} SetForm={HandleForm} />
          <SocialShare form={form} />
          <Meet />
          <Eddie HandleFormProp={HandleForm} formBool={form} />
          <motion.div
            id="background"
            className="h-[100svh]"
            initial={{ filter: "blur(0px)" }}
            animate={form ? { filter: "blur(50px)" } : {}}
            transition={{ ease: "easeIn", duration: 0.5 }}
          >
            <EddieImg form={form} />
            <img
              src={HOME_BG.vignette}
              className={" absolute z-[2] h-[100svh] w-screen"}
            />
            <motion.div className="absolute w-screen h-[100svh]">
              <motion.img
                src={HOME_BG.bg_img}
                className="m-auto absolute left-[0] right-[0] bottom-0 "
                initial={
                  viewportWidth < 1080
                    ? { width: "85vw", height: "85vh", opacity: "0%" }
                    : { width: "70vw", height: "70vh", opacity: "0%" }
                }
                animate={{ width: "100vw", height: "100vh", opacity: "100%" }}
                transition={{
                  delay: 3.7,
                  ease: "anticipate",
                  type: "tween",
                  duration: 3,
                }}
              />
            </motion.div>
          </motion.div>
        </motion.div>
      </body>
    </html>
  );
};

export default Home;

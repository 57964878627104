import Privacy from "./Components/Privacy";
import Eddie from "./Components/eddie";
import { HOME_BG } from "./Images";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="privacy" element={<Privacy />} />
    </Routes>
    // <Home />
  );
}

const HOME_BG = {
  bg_img: require("./vincentiu-solomon-ln5drpv_ImI-unsplash.jpg"),
  eddie: require("./eddie.png"),
  vignette: require("./vignette.png"),
  button: require("./ButtonBorder.png"),
  formbg: require("./Blur.png"),
  EddieLogo: require("./Logo_Eddie.png"),
  ShareLogo: require("./Group 2.png"),
  error: require("./error.png"),
  submitted: require("./submitted.png"),
  email: require("./icon Email.png"),
};

export { HOME_BG };

import React, { useState, useRef } from "react";
import { HOME_BG } from "../Images";
import { motion } from "framer-motion";
import InputField from "./Components/inputField";

import { Link } from "react-router-dom";

const Form = (props) => {
  const [submit, setSubmit] = useState(false);
  const [err, setErr] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");

  const [number, setNumber] = useState("");

  const [email, setEmail] = useState("");

  const SetName = (value) => {
    setName(value);
  };

  const SetSurname = (value) => {
    setSurname(value);
  };

  const SetNumber = (value) => {
    setNumber(value);
  };
  const SetEmail = (value) => {
    setEmail(value);
  };

  const resetForm = () => {
    setEmail("");

    setName("");
    setNumber("");
    setSurname("");
  };
  const formRef = useRef();

  const scriptUrl =
    "https://script.google.com/macros/s/AKfycbzH5UX_CxpP7YgXO5vwM9b9Zp_ODwa_eGDrS9r4r3FsP-dXXx_f2b8fZRhCSweNBZ8fow/exec";

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name && surname && email && number) {
      fetch(scriptUrl, { method: "POST", body: new FormData(formRef.current) })
        .then((res) => {
          setSubmit(true);
          setSubmitting(false);
          resetForm();
          console.log(name + surname + email + number);
        })
        .catch((err) => {
          console.log(err);
          setErr(true);
        });
    }
  };

  return (
    <motion.div
      id="form"
      className={
        props.form
          ? "absolute m-auto left-0 right-0 top-0 pt-7 sm:w-screen md:w-[70%] lg:w-[40%] md:h-screen h-[100svh] bg-transparent z-40 "
          : "hidden"
      }
      s
      initial={{ opacity: 0 }}
      animate={props.form ? { opacity: 1 } : {}}
      transition={{ delay: 2, ease: "easeIn" }}
    >
      <motion.div className=" mt-[7rem] md:mt-32 text-white text-xl">
        be the first to know about eddie.
      </motion.div>
      <motion.div className="md:mt-12 mt-8">
        <form
          id="contactForm"
          ref={formRef}
          onSubmit={handleSubmit}
          className={
            submit ? "hidden" : "relative flex flex-col gap-3 md:gap-4 "
          }
          method="POST"
        >
          <InputField
            label="first name*"
            name="name"
            type="text"
            OnChange={SetName}
            value={name}
          />

          <InputField
            label="last name*"
            name="surname"
            type="text"
            OnChange={SetSurname}
            value={surname}
          />

          <InputField
            label="email*"
            name="email"
            type="email"
            OnChange={SetEmail}
            value={email}
          />

          <InputField
            label="mobile number*"
            name="number"
            type="number"
            OnChange={SetNumber}
            value={number}
            typeN={true}
          />

          <div className=" m-auto left-0 right-0  text-left w-[83%] h-1/4  text-gray-400">
            {/* <a className=" hover:text-gray-300 underline" href="">
              Privacy Policy & Terms
            </a> */}
            <Link
              to="privacy"
              className="hover:text-gray-300 underline"
              target="_blank"
            >
              Privacy Policy & Terms
            </Link>
          </div>

          <button
            className=" m-auto w-52 mt-6 md:pt-0 px-8 h-12  text-white hover:bg-transparent left-0 right-0 font-semibold  rounded-full"
            type="submit"
            onClick={() => {
              if (name && surname && email && number) {
                setSubmitting(true);
              }
            }}
          >
            <img
              src={HOME_BG.button}
              className=" absolute ml-[-1.8rem] mt-[-0.5rem] w-52 scale-95"
            />
            <span>{submitting ? "submitting..." : "submit"}</span>
          </button>
        </form>
        <div className={submit || err ? "text-white w-full h-full " : "hidden"}>
          <img
            src={submit ? HOME_BG.submitted : HOME_BG.error}
            className="m-auto left-0 right-0"
          />
          <div className=" pt-3">
            {submit
              ? "thank you, we will get  back to you soon"
              : "apologies, it appears there was an issue. please try again."}
          </div>
          <button
            className=" m-auto w-fit px-8 pt-12 h-12 text-white hover:bg-transparent left-0 right-0 font-semibold  rounded-full"
            type="submit"
            onClick={() => {
              props.SetForm();
              setSubmit(false);
              setErr(false);
            }}
          >
            <img
              src={HOME_BG.button}
              className=" absolute ml-[-4.8rem] mt-[-0.5rem] w-52 scale-95"
            />
            <span>go back</span>
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Form;
